.headroom {
    will-change: transform;
    background-color: inherit;
    @include transition($transition-base);
}
.headroom--pinned {
    @extend .position-fixed;
    transform: translateY(0%);
}
.headroom--unpinned {
    @extend .position-fixed;
    transform: translateY(-100%);
}

.headroom--not-top {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #fdfdfe !important;
    box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

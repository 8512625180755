iframe {
    border: 0;
}

figcaption,
figure,
main {
    display: block;
}

main {
    overflow: hidden;
}

button:focus {
    outline: none;
    outline-color: transparent;
}
